import React from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { menuList } from '../contant';
import Link from 'next/link';

const PcFooter: React.FC = () => {
    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-center']}>
                <div className={styles['footer-info']}>
                    <div className={styles['footer-left']}>
                        <div className={styles['footer-logo']}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/finovy-cloud-website/img/footer/footer-logo-new.png"
                                alt="logo"
                            />
                        </div>
                        <div className={styles['contact-us']}>
                            <span>{intl.get('Menu.ContactUs').d('联系我们')}</span>
                        </div>
                        <div className={styles['contact-email']}>
                            <IconFont className={styles['email-icon']} type="icon-mail-fill" />
                            {intl.get('Common.Mail').d('邮箱：')}
                            {intl.get('Common.MailValue').d('sales@cloud.doo.com')}
                        </div>
                        <div className={styles['contact-email']}>
                            <IconFont className={styles['email-icon']} type="icon-phone-fill" />
                            <span>{intl.get('Common.Telephone').d('电话：')}</span>
                            <p>
                                {/* <span>{intl.get('Common.Singapore').d('新加坡')} +65 6011 1736</span> */}
                                <span>
                                    {intl.get('Common.Hongkong').d('香港')}&nbsp;
                                    {intl.get('Common.PhoneNumber').d('+852 59503262')}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className={styles['footer-right']}>
                        <div className={styles['footer-right-box']}>
                            {menuList.map((mItem) => (
                                <div className={styles['nav-item']} key={mItem.key}>
                                    <p className={styles['nav-tit']}>{intl.get(mItem.name).d(mItem.name)}</p>
                                    <div className={styles['nav-line']} />
                                    <ul className={styles['footer-nav-list']}>
                                        {mItem.children.map((item) => (
                                            <li key={mItem.key + item.key}>
                                                <Link href={item.url}>
                                                    <span className={styles.fileInfo}>
                                                        {intl.get(item.name).d(item.name)}
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['footer-bottom']}>
                <div className={styles['footer-copyright']}>
                    <span>
                        Copyright © 2024 Doo Cloud. All Rights Reserved
                        <a
                            style={{ marginLeft: 25, color: 'rgba(255, 255, 255, 0.45)' }}
                            href="https://cloudhelp.notion.site/Doo-Cloud-7fef6eb67a3f45db843b8574154790a6?pvs=4"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {intl.get('Global.PrivacyAgreement').d('隐私协议')}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};
export default PcFooter;
