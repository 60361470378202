import { Avatar, Dropdown } from 'antd';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React, { useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import type { MenuProps } from 'antd';

import styles from './index.module.less';
import { navList } from '../constant';
import type { ItemsType } from '../type';
import IconFont from '@/components/common/IconFont';
import type { LanguageType, UserInfoResponse } from '@/services/common/typing';
import { setCookie, getCookie, delCookie, toConsolePath } from '@/utils/utils';
import { logout } from '@/services/common';
import { LogoutOutlined } from '@ant-design/icons';
interface IProps {
    useInfo?: UserInfoResponse;
}

const PcHeader: React.FC<IProps> = (props) => {
    const { useInfo } = props;
    const headSrc = useInfo?.head || '/business/oss-service/finovy-cloud-website/img/header/avatar_active_new.png';
    const router = useRouter();
    const [siteKey, setSiteKey] = useState<LanguageType>('en-US');
    const cls = (item: ItemsType) => {
        if (item.children && item.children.some((item2: ItemsType) => item2.url === router.asPath)) {
            return styles.act;
        }
        if (item.url === router.asPath) {
            return styles.act;
        }
        return undefined;
    };

    /**
     * 退出登录
     */
    const loginOut = async () => {
        try {
            const res = await logout();
            if (res.code === 0) {
                delCookie('Authorization');
                window.location.reload();
            }
        } catch (error) {}
    };
    const menusItems = [
        {
            label: (
                <div className={styles.logout} onClick={loginOut}>
                    <LogoutOutlined />
                    &nbsp;{intl.get('Global.LoginOut').d('退出登录')}
                </div>
            ),
            key: '1',
        },
    ];

    useEffect(() => {
        const lang = getCookie('lang') || localStorage.getItem('lang') || 'en-US';
        setSiteKey(lang as LanguageType);
    }, []);

    const siteMenusItems = useMemo(() => {
        return [
            {
                key: 'zh-CN',
                label: '简体中文',
            },
            {
                key: 'zh-TW',
                label: '繁体中文',
            },
            {
                key: 'en-US',
                label: 'English',
            },
        ];
    }, []);

    const items = [
        {
            key: 'zh-CN',
            label: <p className={styles['items-p']}>简体中文</p>,
        },
        {
            key: 'zh-TW',
            label: <p className={styles['items-p']}>繁体中文</p>,
        },
        {
            key: 'en-US',
            label: <p className={styles['items-p']}>English</p>,
        },
    ];

    const siteName = useMemo(() => {
        const { label } = siteMenusItems.find((item) => item.key === siteKey) || {};
        return label;
    }, [siteKey, siteMenusItems]);

    /**
     * 切换语言
     * @param val
     */
    const onChangeSite: MenuProps['onClick'] = ({ key }) => {
        if (key !== siteKey) {
            delCookie('lang');
            setSiteKey(key as LanguageType);
            setCookie('lang', key, 7);
            localStorage.setItem('lang', key);
            window.location.reload();
        }
    };

    return (
        <div className={styles['header-container']}>
            <div className={styles.menuContainer}>
                <div className={styles['header-left']}>
                    <Link href="/" className={styles.logo}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/finovy-cloud-website/img/header/header-logo-new.png"
                            alt="logo"
                        ></img>
                    </Link>
                    <ul className={styles.menuList}>
                        {navList.map((item) => {
                            return (
                                <li className={styles.menuLi} key={item.id}>
                                    <Link href={item.url || ''} title={intl.get(item.text)} className={cls(item)}>
                                        {intl.get(item.text).d(item.text)}
                                    </Link>
                                    {item.children && (
                                        <div className={styles['nav-box']}>
                                            {item.children.map((item2) => {
                                                return (
                                                    <Link
                                                        className={classnames(styles['nav-item'])}
                                                        href={item2.url}
                                                        key={item2.id}
                                                    >
                                                        <p>{intl.get(item2.text).d(item2.text)}</p>
                                                        <p>{intl.get(item2.desc).d(item2.desc)}</p>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className={styles['header-right']}>
                    <div className={styles['header-right-con']}>
                        {/* 隐藏两官网的跳转 */}
                        {/* <div className={styles.logBtn}>
                            <Dropdown
                                menu={{ items: siteMenusItems, onClick: changeSite }}
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode;
                                }}
                                placement="bottom"
                            >
                                <div className={styles['site-box']} onClick={(e) => e.preventDefault()}>
                                    {stieObj.en}
                                    <DownOutlined
                                        style={{
                                            color: 'rgba(2, 10, 26, 0.25)',
                                        }}
                                    />
                                </div>
                            </Dropdown>
                        </div> */}
                        <div
                            className={styles.logBtn}
                            onClick={() => {
                                toConsolePath({
                                    path: '/account/center/detail',
                                    isTarget: true,
                                });
                            }}
                        >
                            <IconFont type="icon-house" className={styles.console_icon}></IconFont>
                            {intl.get('Header.Console').d('控制台')}
                        </div>
                        <div className={styles.language} id="#languageHeader">
                            <Dropdown
                                placement="bottom"
                                menu={{
                                    items,
                                    onClick: onChangeSite,
                                }}
                                getPopupContainer={(triggerNode: HTMLElement) => {
                                    return triggerNode.parentNode as HTMLElement;
                                }}
                            >
                                <div>
                                    <IconFont type="icon-global" className={styles.language_icon}></IconFont>
                                    <span className={styles.language_txt}>{siteName}</span>
                                </div>
                            </Dropdown>
                        </div>
                        {!useInfo && (
                            <div
                                className={styles.logBtn}
                                onClick={() => {
                                    toConsolePath({
                                        path: '/login',
                                        isTarget: true,
                                        isNotAuth: true,
                                        isRedirect: true,
                                    });
                                }}
                            >
                                {intl.get('Global.Login').d('登录')}
                            </div>
                        )}
                        {!!useInfo && (
                            <div className={classnames(styles.logBtn, styles['log-user-info'])}>
                                <Dropdown
                                    menu={{ items: menusItems }}
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode;
                                    }}
                                    placement="bottom"
                                >
                                    <div className={styles['user-info']}>
                                        <Avatar src={headSrc} size={40} alt={useInfo?.name}></Avatar>
                                    </div>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                    {!useInfo && (
                        <div
                            id="analytics-register"
                            className={styles['free-reg']}
                            onClick={() => {
                                toConsolePath({
                                    path: '/register',
                                    isNotAuth: true,
                                    isTarget: true,
                                    isRedirect: true,
                                });
                            }}
                        >
                            {intl.get('Header.Register').d('免费注册')}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default PcHeader;
