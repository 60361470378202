import React from 'react';

import MFooter from './m';
import PcFooter from './pc';
import TransferCom from '../../transferCom';

const Footer: React.FC = () => {
    return <TransferCom m={<MFooter />} ipad={null} pc={<PcFooter />} />;
};
export default Footer;
