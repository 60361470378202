import { Get, Post } from '@fvc/request';
// import type { API } from '@fvc/request';
import type {
    CaptchaConfigRes,
    CaptchaValidateParams,
    CaptchaValidateRes,
    CodeSendParams,
    // ContactParams,
    DomainConfig,
    GetLanguageParams,
    UserInfoResponse,
    IpPhoneCodeResponse,
} from './typing';
const nextMtsUrl = process.env.NEXT_MTS_URL || 'https://mts-xds-dev.oss-cn-shenzhen.aliyuncs.com';

/** 发送邮件 node接口 */
// export const sendEmail = (params: ContactParams) => {
//     return Post<null>(`/v1/send/email`, params, { baseURL: '' });
// };

/** 获取mts多语言 */
export const getMtsLanguage = async (params: GetLanguageParams) => {
    const { lang, url = 'fvc_intl_website' } = params;
    // return Get<any>(
    //     `/multilingual-translation-system/${url}/${lang}.json`,
    //     {},
    //     {
    //         baseURL: nextMtsUrl,
    //         showError: false,
    //     },
    // );
    return await fetch(`${nextMtsUrl}/multilingual-translation-system/${url}/${lang}.json`).then((res) => res.json());
};

/** 获取登录用户信息 */
export const getUserInfo = () => {
    return Get<UserInfoResponse>(`/client-auth/v1/user/info`, {});
};

/** 获取滑动配置 */
export const getCaptchaConfig = () => {
    return Get<CaptchaConfigRes>(`/client-third-verify/v1/captcha/config`, { dictType: 'app-register-international' });
};

/** 验证滑动结果 */
export const getCaptchaValidate = (params: CaptchaValidateParams) => {
    return Post<CaptchaValidateRes>(`/client-third-verify/v1/captcha/validate`, params);
};

/** 获取配置项 */
export const getDomainName = () => {
    return Get<DomainConfig[]>(`/client-config/v1/domain-name`);
};

/** 发送验证码 */
export const codeSend = (params: CodeSendParams) => {
    return Post<string>(`/client-third-verify/v1/code/send`, params);
};

/** 退出登录 */
export const logout = () => {
    return Post(`/client-auth/v1/user/logout`, {});
};

/** 获取单位配置项 */
export const getCurrencyConfig = () => {
    return Get<DomainConfig[]>(`/client-fund/v1/config/get`);
};

export interface SlideSource {
    slideSource: {
        cutImage: string;
        cutImageHeight: number;
        cutImageWidth: number;
        oriImage: string;
        xpos: number;
        ypos: number;
    };
    slideToken: string;
}

// 获取校验本地配置
// eslint-disable-next-line new-cap
export const captchaLocalConfig = () =>
    Get<SlideSource>(`/cloud-common/v1/captcha/local-config`, {}, { isLoginExpiredJump: true });

// 验证本地资源
export const captchaLocalValidate = (data: { slideToken: string; slideDistance: number }) =>
    Post('/cloud-common/v1/captcha/local-validate', data, { isLoginExpiredJump: true });

interface RibbonValuePair {
    key: string;
    value: string;
}

export interface SendEmailParams {
    emailKey: 'cloud_collection' | 'cloud_consulting_collection';
    lang: 'zh-CN';
    codeToken: string;
    ribbonValue: RibbonValuePair[];
}

// 发送邮件
export const sendEmail = (data: SendEmailParams) =>
    Post('/cloud-common/v1/email/send', data, { isLoginExpiredJump: true });

// 获取IP所在 国家code 及 phone code

export const getCountryIp = (data?: { ip: string }) =>
    Get<IpPhoneCodeResponse>(`/cloud-common/v1/country/info`, data || {}, { showError: false });
