// import { openGraph } from '@/lib/helper';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import intl from 'react-intl-universal';

import { PageSeoMeta } from './constant';
import type { DefaultMeta, SeoProps } from './type';

const Seo: React.FC<SeoProps> = (props) => {
    const router = useRouter();
    const defaultMeta: DefaultMeta = {
        title: 'Doo Cloud_全球云基础设施服务提供商',
        siteName: 'Doo Cloud_全球云基础设施服务提供商',
        keywords: 'Doo Cloud，云服务器，服务器托管，服务器租用，服务器采购，IDC 机柜租赁',
        description:
            'Doo Cloud 于 2019 年成立，总部位于新加坡，是一家云基础设施服务供应商，旨在为全球个人与机构客户提供弹性云服务器、IDC 解决方案等产品及服务。',
        url: 'https://cloud.doo.com',
        type: 'website',
        robots: 'follow, index',
        image: '/business/oss-service/finovy-cloud-website/img/seo/Seo-Logo.jpg',
    };

    const meta = {
        ...defaultMeta,
        ...props,
    };
    meta.title = props.templateTitle ? `${props.templateTitle} | ${meta.siteName}` : meta.title;

    // Use siteName if there is templateTitle
    // but show full title if there is none
    // meta['image'] = openGraph({
    //   description: meta.description,
    //   siteName: props.templateTitle ? meta.siteName : meta.title,
    //   templateTitle: props.templateTitle,
    // });
    const seoMeta = useMemo(() => {
        if (PageSeoMeta[router.asPath]) {
            return PageSeoMeta[router.asPath];
        }
        return meta;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PageSeoMeta, meta, router]);
    const metaTitle = seoMeta.title ? intl.get(seoMeta.title) : defaultMeta.title;
    const metaKeywords = seoMeta.keywords ? intl.get(seoMeta.keywords) : defaultMeta.keywords;
    const metaDesc = seoMeta.description ? intl.get(seoMeta.description) : defaultMeta.description;
    return (
        <Head>
            <title>{metaTitle}</title>
            <meta name="robots" content={meta.robots} />
            <meta content={metaKeywords} name="keywords" />
            <meta content={metaDesc} name="description" />
            <meta property="og:url" content={`${meta.url}${router.asPath}`} />
            <link rel="canonical" href={`${meta.url}${router.asPath}`} />
            {/* Open Graph */}
            <meta property="og:type" content={meta.type} />
            <meta property="og:site_name" content={metaTitle} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:title" content={metaTitle} />
            <meta name="image" property="og:image" content={meta.image} />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@th_clarence" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDesc} />
            <meta name="twitter:image" content={meta.image} />
            {meta.date && (
                <>
                    <meta property="article:published_time" content={meta.date} />
                    <meta name="publish_date" property="og:publish_date" content={meta.date} />
                    <meta name="author" property="article:author" content="Theodorus Clarence" />
                </>
            )}
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/business/oss-service/finovy-cloud-website/img/home/favicon-logo.png"
            ></link>
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default Seo;
