import classnames from 'classnames';
import React from 'react';

import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';

const PcCustomService: React.FC = () => {
    const isEnLang = intl.getInitOptions().currentLocale === 'en-US';
    const router = useRouter();
    const toContactService = () => {
        router.push('/contact/');
    };

    return (
        <div className={styles.container}>
            <div className={classnames(styles.content)} onClick={toContactService}>
                <div className={styles['icon-box']}>
                    <IconFont type="kefu" className={styles.icon}></IconFont>
                </div>
            </div>
            {isEnLang ? (
                <div className={styles.QR_Container}>
                    <div className={styles.QR_in_container}>
                        <div className={styles.qr_en_header}>
                            <span className={styles.qr_en_title}>
                                {intl.get('Contact.QrEnTitle').d('Scan To Add Customer Service')}
                            </span>
                        </div>
                        <div className={styles.qr_en_body}>
                            <div className={styles.QR_box}>
                                <div className={styles.qr_img}>
                                    <img
                                        loading="lazy"
                                        src="/business/oss-service/finovy-cloud-website/img/contact/pc/Telegram_QR.jpg"
                                        alt=""
                                    />
                                </div>
                                <div className={styles.QR_en_desc}>
                                    <span>{intl.get('Contact.TelegramQR').d('QR Code for Telegram')}</span>
                                </div>
                            </div>
                            <div className={styles.QR_box}>
                                <div className={styles.qr_img}>
                                    <img
                                        loading="lazy"
                                        src="/business/oss-service/finovy-cloud-website/img/contact/pc/Whatsapp_QR.jpg"
                                        alt=""
                                    />
                                </div>
                                <div className={styles.QR_en_desc}>
                                    <span>{intl.get('Contact.Whatsapp').d('QR Code for Whatsapp')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.QR_Container}>
                    <div className={styles.QR_in_container}>
                        <div className={styles.qr_header}>
                            <span className={styles.qr_title}>
                                {intl.get('Contact.QrTitle').d('扫一扫添加客服微信')}
                            </span>
                        </div>
                        <div className={styles.qr_body}>
                            <div className={styles.qr_img}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/finovy-cloud-website/img/contact/pc/wx_QR.jpg"
                                    alt=""
                                />
                            </div>
                            <div className={styles.QR_desc}>
                                <span>{intl.get('Contact.QRDesc').d('扫描上面的QR Code，加我为朋友')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default PcCustomService;
