import classnames from 'classnames';
import React, { useState } from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { menuList } from '../contant';
import Link from 'next/link';
const MFooter: React.FC = () => {
    const [navigateMap, setNavigateMap] = useState(menuList);

    const menuRender = () => {
        return navigateMap.map((item: any, index: number) => {
            return (
                <div key={item.key} className={styles['menu-item']}>
                    <div
                        className={styles['item-con']}
                        onClick={() => {
                            const listData = navigateMap;
                            listData.forEach((itm, idx) => {
                                if (index === idx) {
                                    itm.isOpen = !itm.isOpen;
                                } else {
                                    itm.isOpen = false;
                                }
                            });
                            setNavigateMap(JSON.parse(JSON.stringify(listData)));
                        }}
                    >
                        <p className={styles['footer-nav-tit']}>{intl.get(item.name).d(item.name)}</p>
                        <IconFont
                            className={classnames(styles.xiaojiangtou, item.isOpen ? styles.act : null)}
                            type="icon-jiantou"
                        />
                    </div>
                    <div className={styles['parting-line']} />
                    {item.isOpen &&
                        item.children.map((subItem: any) => {
                            return (
                                <div className={styles['sub-item']} key={subItem.key}>
                                    <span>
                                        <Link href={subItem.url}>{intl.get(subItem.name).d(subItem.name)}</Link>
                                    </span>
                                </div>
                            );
                        })}{' '}
                </div>
            );
        });
    };

    return (
        <div className={styles['footer-container']}>
            <div className={styles['footer-info']}>
                <div className={styles['footer-nav-menu']}>{menuRender()}</div>
                <div className={styles['footer-logo']}>
                    <img
                        loading="lazy"
                        src="/business/oss-service/finovy-cloud-website/img/footer/footer-logo-new.png"
                        alt=""
                    />
                </div>
                <div className={styles['contact-email']}>
                    <IconFont className={styles['email-icon']} type="icon-mail-fill" />
                    {intl.get('Common.Mail').d('邮箱：')} {intl.get('Common.MailValue').d('sales@cloud.doo.com')}
                </div>
                <div className={styles['contact-email']}>
                    <IconFont className={styles['email-icon']} type="icon-phone-fill" />
                    <span>{intl.get('Common.Telephone').d('电话：')}</span>
                    <p>
                        {/* <span>{intl.get('Common.Singapore').d('新加坡')} +65 6011 1736</span> */}
                        <span>
                            {intl.get('Common.Hongkong').d('香港')}&nbsp;
                            {intl.get('Common.PhoneNumber').d('+852 59503262')}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles['footer-bottom']}>
                <div className={styles['footer-copyright']}>
                    <span>
                        Copyright © 2024 Doo Cloud. All Rights Reserved
                        <a
                            style={{ marginLeft: 25, color: 'rgba(255, 255, 255, 0.45)' }}
                            href="https://cloudhelp.notion.site/Doo-Cloud-7fef6eb67a3f45db843b8574154790a6?pvs=4"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {intl.get('Global.PrivacyAgreement').d('隐私协议')}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};
export default MFooter;
