// eslint-disable-next-line @typescript-eslint/no-var-requires
const { LRUCache } = require('lru-cache');

const nextMtsUrl = process.env.NEXT_MTS_URL || 'https://mts-xds-dev.oss-cn-shenzhen.aliyuncs.com';
/** 获取mts多语言 */
const getMtsLanguage = async (params) => {
    const { lang, url = 'fvc_intl_website' } = params;
    const res = await fetch(`${nextMtsUrl}/multilingual-translation-system/${url}/${lang}.json`);
    // eslint-disable-next-line @typescript-eslint/return-await
    return res.json();
};

global.localeCache = new LRUCache({
    max: 100, // 缓存大小
    ttl: 1000 * 60 * 60 * 1, // 毫秒 缓存项存活时间，这里设置为1小时
});

// 获取缓存mts数据
async function getMtsData(lang) {
    if (global.localeCache.has(lang)) {
        console.log(`从缓存中获取语言包: ${lang}`);
        return global.localeCache.get(lang);
    }

    const [mts, commonMts] = await Promise.all([getMtsLanguage({ lang }), getMtsLanguage({ lang, url: 'fvc_common' })]);

    const data = { ...commonMts, ...mts };
    console.log('重新获取语言包: ', lang);
    global.localeCache.set(lang, data);

    return data;
}

async function updateMtsData(lang) {
    const [mts, commonMts] = await Promise.all([getMtsLanguage({ lang }), getMtsLanguage({ lang, url: 'fvc_common' })]);

    const data = { ...commonMts, ...mts };
    console.log('手动获取新语言包: ', lang);

    // 将加载的数据存储在缓存中
    global.localeCache.set(lang, data);

    return data;
}

module.exports = {
    updateMtsData,
    getMtsData,
    getMtsLanguage,
};
