import withTwindApp from '@twind/next/app';
import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import intl from 'react-intl-universal';
import Seo from '@/components/common/Seo';
import { getDomainName, getCurrencyConfig, getCountryIp } from '@/services/common';
import { type DomainObj } from '@/services/common/typing';
import { apiLangMap } from '@/constant/common';
import { getMtsData } from '@/utils/cache';

import twindConfig from '../../twind.config';
import { Inspector } from 'react-dev-inspector';
import '@/styles/globals.less';
import 'antd/es/style/themes/default.less';
import 'antd/dist/antd.less'; // 引入官方提供的 less 样式入口文件
import '@/assets/fonts/font.css'; // 引入字体
import Layout from '@/components/common/layout/Layout';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useLayoutEffect } from 'react';
import utmRecognition from '@/utils/utmRecognition';
import { notification, message } from 'antd';
import { jsonParseLoop, getCookie, delCookie, setCookie, getIpLocale, getHeaderIp } from '@/utils/utils';
import NProgress from 'nprogress';
import { UpdateParam } from '@fvc/request';
import 'nprogress/nprogress.css';

UpdateParam({
    baseURL: !globalThis.window ? process.env.API_HOST + '/business' : '/business',
    isLoginExpiredJump: true,
});

message.config({
    top: 108,
    duration: 2,
});

notification.config({
    top: 108,
    duration: 2,
});

function withDevInspector(children: JSX.Element) {
    return <Inspector>{children}</Inspector>;
}

const normalMtsLangs = ['zh-CN', 'zh-TW', 'en-US'];

// 用于Lang[Key]历史遗留问题转换
const oldIntlLangKeyMap: Record<string, string> = {
    'zh-WT': 'zh-TW',
    'zh-CNT': 'zh-TW',
};

let lang = 'en-US';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const domainNameObj: DomainObj = {
    'domain-name-buy': '',
    'domain-name-console': '',
    'domain-name-pay': '',
    'domain-name-www': '',
    'domain-name-shared': '',
};

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
});

Router.events.on('routeChangeStart', () => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();
    const { lang: langKey } = pageProps || {};
    router.locale = lang;

    const langList = (pageProps.lang || navigator.language || 'en-US').split('-');
    lang = langList[0] + '-' + langList[langList.length - 1].toLocaleUpperCase();

    intl.init({
        currentLocale: lang,
        locales: {
            [lang]: pageProps.localeData,
        },
    });

    useLayoutEffect(() => {
        // UTM追踪链接参数识别
        utmRecognition();
    }, []);

    const getLayout = Component.getLayout ?? ((page: any) => <Layout>{page}</Layout>);

    // 初始化加载的时候会读取一遍错误的值，这里还需要纠错一下错误的langKey
    let intlProviderLangKey = lang;
    if (oldIntlLangKeyMap[intlProviderLangKey]) {
        // 修复lang[WT]错误的情况
        intlProviderLangKey = oldIntlLangKeyMap[lang];
    }

    const normalComponent = (
        <IntlProvider locale={intlProviderLangKey} defaultLocale={intlProviderLangKey} messages={pageProps.localeData}>
            <Seo />
            {getLayout(<Component {...pageProps} />)}
        </IntlProvider>
    );

    const getDomainNameList = async () => {
        try {
            const [res1, res2] = await Promise.all([getDomainName(), getCurrencyConfig()]);
            const currencyConfig = res2.data ? res2.data.map((item) => ({ ...item, v: jsonParseLoop(item.v) })) : [];
            localStorage.setItem('domains', JSON.stringify(res1.data));
            // 代币单位
            localStorage.setItem('currencyConfig', JSON.stringify(currencyConfig));
            // 单独获取代币单位
            localStorage.setItem(
                'productUnit',
                JSON.stringify(currencyConfig?.find((item) => item.k === 'finance-config')?.v?.productUnit || ''),
            );

            res1.data.forEach((item) => {
                domainNameObj[item.k as keyof DomainObj] = item.v;
            });
        } catch (error) {}
    };

    useEffect(() => {
        // 纠错有问题的cookie，在getInitialProps中已经修复正常，页面当中直接读取判断
        if (getCookie('lang') !== langKey) {
            delCookie('lang');
            setCookie('lang', langKey, 7);
            localStorage.setItem('lang', langKey);
        }

        getDomainNameList();
    }, []);

    return process.env.NEXT_PUBLIC_ENV === 'dev' ? withDevInspector(normalComponent) : normalComponent;
};

// 初始化oss语言
App.getInitialProps = async (props: any) => {
    const { Component, ctx } = props;
    let localeDataList = null;
    let pageProps: Record<string, any> = {};

    const env_tag = process.env.ENV_TAG;

    // 动态ip 获取 locale
    let locale = '';
    const apiUrl = process.env.API_HOST;

    const ip = getHeaderIp(ctx?.req?.headers || {});

    // console.log(111, 'ip', ip);
    // console.log(222, 'ctx?.req?.headers', ctx?.req?.headers);

    if (!global.window) {
        try {
            const res = await getCountryIp({ ip });
            const { code, data } = res;
            const { n2nKey } = data || {};
            console.log('getCountryIp', apiUrl, res);

            if (code === 0 && n2nKey) {
                locale = getIpLocale(n2nKey);
            }
        } catch (err) {
            console.log(err);
        }
    }

    // 获取cookies中的语言
    let cookieLang = '';
    if (!global.window) {
        cookieLang = ctx.req.cookies.lang || locale || 'zh-CN';
    } else {
        cookieLang = getCookie('lang') || locale || 'zh-CN';
    }

    if (!global.window ? ctx.req.cookies.lang : getCookie('lang')) {
        // 修复lang[WT]错误的情况

        if (oldIntlLangKeyMap[cookieLang]) {
            const newLangValue = oldIntlLangKeyMap[cookieLang]; // 新的语言值
            cookieLang = newLangValue;
        }
        if (!normalMtsLangs.includes(cookieLang)) {
            cookieLang = 'en-US';
        }
    }
    // const ipRes = await getCountryIp(`${apiUrl}/business1`, { ip });
    // console.log(111, 'ipRes', ipRes);
    // try {
    //     // 防止其他类型值匹配进来没有语言包匹配的情况
    //     const langJson = apiLangMap[cookieLang] || 'en_us';

    //     const [res, commonRes] = await Promise.all([
    //         getMtsLanguage({ lang: langJson }),
    //         getMtsLanguage({ lang: langJson, url: 'fvc_common' }),
    //     ]);

    //     localeDataList = { ...res, ...commonRes };
    if (!global.window) {
        (global as any).ip_header = ctx.req.headers;
    }
    try {
        // 防止其他类型值匹配进来没有语言包匹配的情况
        const langJson = (apiLangMap as any)[cookieLang || 'en-US'];

        // const [res, commonRes] = await Promise.all([
        //     getMtsLanguage({ lang: langJson }),
        //     getMtsLanguage({ lang: langJson, url: 'fvc_common' }),
        // ]);
        localeDataList = await getMtsData(langJson);
        pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        pageProps = {
            ...pageProps,
            env_tag,
            lang: cookieLang,
            localeData: localeDataList,
            ip: (global as any).ip_header,
        };
    } catch (error) {
        pageProps = { ...pageProps, env_tag, lang: cookieLang, localeData: {} };
    }

    return {
        pageProps,
    };
};

export default withTwindApp(twindConfig, App);
