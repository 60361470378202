import { type DomainObj } from './types';
/**
 * @description 跳转
 **/
export const toJump = (url: string) => {
    if (!url) return;
    window.location.href = url;
};
export const evnInfo = () => {
    const href = window.location.href;
    if (href.includes('192.')) {
        return true;
    }
    if (href.includes('localhost')) {
        return true;
    }
    return false;
};
export const getDomainNameList = () => {
    const domainNameObj: Partial<DomainObj> = {};
    const domains = localStorage.getItem('domains');
    if (domains) {
        const domainsOpt = JSON.parse(domains);
        domainsOpt.forEach((item: { k: string; v: string }) => {
            domainNameObj[item.k as keyof DomainObj] = item.v;
        });
    }
    return domainNameObj;
};

export const setCookie = (name: string, value: string, timer?: string | number, domain?: string) => {
    const exp: Date = new Date();
    exp.setHours(exp.getHours() + 2);
    const localEnvFlag = evnInfo();
    let domainStr = ''; // domainsCookie['domain-name-shared'];

    if (localEnvFlag) {
        domainStr = 'localhost';
    } else {
        const hostname = window.location.hostname;
        const nameList = hostname.split('.');
        domainStr = `.${nameList[nameList.length - 2]}.${nameList[nameList.length - 1]}`;
    }
    document.cookie = `${name}=${value}; expires=${timer || exp.toUTCString()};domain=${domainStr}; path=/`;
};

// 获取cookie
export const getCookie = (name: string) => {
    try {
        let arr;
        const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};

export const delCookie = (name: string) => {
    const exp: any = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = getCookie(name);
    const localEnvFlag = evnInfo();
    const domainsCookie = getDomainNameList();
    let domainStr = domainsCookie['domain-name-shared'];

    if (localEnvFlag) {
        domainStr = 'localhost';
    }
    if (cval != null) {
        document.cookie = `${name}=${cval};expires=${exp.toGMTString()};domain=${domainStr}; path=/`;
    }
};

// 控制台跳转
export const getConsoleUrl = (params: { path: string }) => {
    const { protocol } = window.location;
    const evnFlag = evnInfo();
    const domainOpt = getDomainNameList();
    let url = `${domainOpt['domain-name-console']}${params.path}`;
    if (evnFlag) {
        url = `${protocol}//localhost:8003${params.path}`;
    }
    return url;
};

// 订购页面跳转处理
export const toBuyPath = (params: { path: string; isTarget?: boolean; isReplace?: boolean }) => {
    const { protocol } = window.location;
    const evnFlag = evnInfo();
    const domainOpt = getDomainNameList();
    let url = `${domainOpt['domain-name-buy']}${params.path}`;
    if (evnFlag) {
        url = `${protocol}//localhost:8002${params.path}`;
    }
    if (!getCookie('Authorization')) {
        url = `${domainOpt['domain-name-console']}/login?redirectUrl=${encodeURIComponent(url)}`;
    }
    if (params.isTarget) {
        window.open(url);
    } else if (params.isReplace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};
// 控制台跳转
export const toConsolePath = (params: {
    path: string;
    isTarget?: boolean;
    isReplace?: boolean;
    isNotAuth?: boolean;
    isRedirect?: boolean;
}) => {
    const { protocol, href } = window.location;
    const evnFlag = evnInfo();
    const domainOpt = getDomainNameList();
    let url = `${domainOpt['domain-name-console']}${params.path}${
        params.isRedirect ? `?redirectUrl=${encodeURIComponent(href)}` : ''
    }`;
    if (evnFlag) {
        url = `${protocol}//localhost:8003${params.path}${
            params.isRedirect ? `?redirectUrl=${encodeURIComponent(href)}` : ''
        }`;
    }

    if (!evnFlag && !params.isNotAuth && !getCookie('Authorization')) {
        url = `${domainOpt['domain-name-console']}/login?redirectUrl=${encodeURIComponent(url)}`;
    }

    if (params.isTarget) {
        window.open(url);
    } else if (params.isReplace) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
};

export const numberRetain = (num: number, decimal: number) => {
    let numStr = num.toString();
    const index = numStr.indexOf('.');
    if (index !== -1) {
        numStr = numStr.substring(0, decimal + index + 1);
    } else {
        numStr = numStr.substring(0);
    }
    return parseFloat(numStr).toFixed(decimal);
};

// 检查年龄是否大于等于周岁18岁
export const isOverUsingTimestamp = (dateString: string) => {
    const birthDate = new Date(dateString);

    const currentDate = new Date();
    const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();

    if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
        return ageDifference - 1 >= 18;
    } else {
        return ageDifference >= 18;
    }
};

/**
 * 格式化jsonParse 防止传入参数非json
 * @param jsonString
 * @param errorObejct
 * @returns
 */
export const jsonParse = <T = any>(jsonString: string, errorObejct?: T): T => {
    try {
        return JSON.parse(jsonString);
    } catch {
        return errorObejct ?? ({} as any);
    }
};

// 递归解析 JSON 字符串并访问深层嵌套数据
export function jsonParseLoop(jsonData: string) {
    const data = jsonParse(jsonData);
    for (const key in data) {
        if (['Object', 'Boolean', 'Array'].includes(Object.prototype.toString.call(data[key]))) {
            data[key] = jsonParseLoop(jsonParse(data[key]));
        }
    }
    return data;
}

/**
 * 根据地区获取 当前所在地区ip
 * @param code
 * @returns
 */
export const getIpLocale = (code: string) => {
    const locales = [
        { value: ['CN'], label: '简体中文', locale: 'zh-CN' },
        { value: ['HK', 'MO', 'TW'], label: '繁體中文', locale: 'zh-TW' },
        { value: [], label: 'English', locale: 'en-US' },
    ];
    const item = locales.find((item: any) => item.value.includes(code));

    return item?.locale || 'en-US';
};

/**
 * 获取头部ip
 * @returns
 */
export const getHeaderIp = (headers: any) => {
    const forwarded = headers['x-forwarded-for'];
    const forwardedIp = forwarded ? forwarded.split(/, /)[0] : '';
    return (
        headers['x-original-forwarded-for'] ||
        headers['x-real-ip'] ||
        headers['wl-proxy-client-ip'] ||
        forwardedIp ||
        headers['proxy-client-ip'] ||
        ''
    );
};

/**
 * 获取html lang
 * @returns
 */
export const getHtmlLang = (l: string) => {
    // 简体中文页面：html lang=zh-cmn-Hans
    // 繁体中文页面：html lang=zh-cmn-Hant
    // 英语页面：html lang=en

    const langMts: Record<string, string> = {
        'zh-CN': 'zh-cmn-Hans',
        'zh-TW': 'zh-cmn-Hant',
        'en-US': 'en',
    };

    return langMts[l] || 'en';
};
