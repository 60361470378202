import { Avatar, Dropdown } from 'antd';
import React, { useState, useEffect } from 'react';
import IconFont from '@/components/common/IconFont';
import styles from './index.module.less';
import type { MenuProps } from 'antd';
import MenuSlide from './components/MenuSlide';
import type { LanguageType, UserInfoResponse } from '@/services/common/typing';
import { setCookie, getCookie, delCookie, toConsolePath } from '@/utils/utils';
import intl from 'react-intl-universal';
import { UserOutlined } from '@ant-design/icons';
import UserInfoModal from './components/UserInfoModal';
import Link from 'next/link';

interface IProps {
    useInfo?: UserInfoResponse;
}

const MHeader: React.FC<IProps> = (props) => {
    const { useInfo } = props;
    const [isShow, setIsShow] = useState<boolean>(false);
    const [siteKey, setSiteKey] = useState<LanguageType>('en-US');
    const [isUserInfoShow, setIsUserInfoShow] = useState<boolean>(false);

    // const changeLang: MenuProps['onClick'] = ({ key }) => {
    //     if (key === 'cn') {
    //         window.location.href = 'https://cloud.finovy.cn/';
    //     }
    // };
    // const stieObj = useMemo(() => {
    //     return {
    //         cn: intl.get('Header.CnStie').d('中国站'),
    //         en: intl.get('Header.EnStie').d('国际站'),
    //     };
    // }, []);
    // const siteMenusItems = [
    //     {
    //         label: <div className={styles['stie-item']}>{intl.get('Header.CnStie').d('中国站')}</div>,
    //         key: 'cn',
    //     },
    //     {
    //         label: <div className={styles['stie-item']}>{intl.get('Header.EnStie').d('国际站')}</div>,
    //         key: 'en',
    //     },
    // ];
    useEffect(() => {
        const lang = getCookie('lang') || localStorage.getItem('lang') || 'en-US';
        setSiteKey(lang as LanguageType);
    }, []);

    const userItems = [
        {
            label: <div className={styles['stie-item-user']}>{intl.get('Global.Login').d('登录')}</div>,
            key: 'login',
        },
        {
            label: (
                <div className={styles['stie-item-user']} id="analytics-register">
                    {intl.get('Global.Register').d('注册')}
                </div>
            ),
            key: 'register',
        },
    ];

    const items = [
        {
            key: 'zh-CN',
            label: <p className={styles['items-p']}>简体中文</p>,
        },
        {
            key: 'zh-TW',
            label: <p className={styles['items-p']}>繁体中文</p>,
        },
        {
            key: 'en-US',
            label: <p className={styles['items-p']}>English</p>,
        },
    ];

    const toPageDropDown: MenuProps['onClick'] = ({ key }) => {
        toConsolePath({
            path: `/${key}`,
            isTarget: true,
            isNotAuth: true,
            isRedirect: true,
        });
    };

    /**
     * 切换语言
     * @param val
     */
    const onChangeSite: MenuProps['onClick'] = ({ key }) => {
        if (key !== siteKey) {
            delCookie('lang');
            setSiteKey(key as LanguageType);
            setCookie('lang', key, 7);
            localStorage.setItem('lang', key);
            window.location.reload();
        }
    };
    return (
        <>
            <div className={styles['header-container']}>
                <div className={styles.menuContainer}>
                    <Link href="/" className={styles.logo}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/finovy-cloud-website/img/header/header-logo-new.png"
                            alt="logo"
                        ></img>
                    </Link>
                    <div className={styles['header-right']}>
                        {/* <Dropdown
                            placement="bottom"
                            menu={{
                                items: siteMenusItems,
                                onClick: changeLang,
                            }}
                            trigger={['click']}
                            getPopupContainer={(triggerNode: HTMLElement) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                        >
                            <div className={styles['site-box']} onClick={(e) => e.preventDefault()}>
                                {stieObj.en}
                                <DownOutlined
                                    className={styles['down-out-icon']}
                                    style={{
                                        color: 'rgba(2, 10, 26, 0.25)',
                                    }}
                                />
                            </div>
                        </Dropdown> */}
                        <Dropdown
                            placement="bottom"
                            menu={{
                                items,
                                onClick: onChangeSite,
                            }}
                            trigger={['click']}
                            getPopupContainer={(triggerNode: HTMLElement) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                        >
                            <IconFont type="icon-yuyan" className={styles['icon-yuyan']}></IconFont>
                        </Dropdown>
                        {isUserInfoShow && (
                            <div
                                className={styles['icon-menu']}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsUserInfoShow(false);
                                }}
                            >
                                <IconFont type="icon-guanbi"></IconFont>
                            </div>
                        )}
                        {!isUserInfoShow && (
                            <>
                                {/* <Dropdown
                                placement="bottom"
                                menu={{
                                    items,
                                    onClick: changeLang,
                                }}
                                trigger={['click']}
                                getPopupContainer={(triggerNode: HTMLElement) => {
                                    return triggerNode.parentNode as HTMLElement;
                                }}
                            >
                                <div className={styles['site-box']} onClick={(e) => e.preventDefault()}>
                                    {stieObj[siteKey]}
                                    <DownOutlined
                                        className={styles['down-out-icon']}
                                        style={{
                                            color: 'rgba(2, 10, 26, 0.25)',
                                        }}
                                    />
                                </div>
                            </Dropdown> */}
                                {!!useInfo && (
                                    <div
                                        className={styles['user-info']}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsUserInfoShow(true);
                                        }}
                                    >
                                        <Avatar icon={<UserOutlined />}></Avatar>
                                    </div>
                                )}
                                {!useInfo && (
                                    <Dropdown
                                        placement="bottom"
                                        menu={{
                                            items: userItems,
                                            onClick: toPageDropDown,
                                        }}
                                        trigger={['click']}
                                        getPopupContainer={(triggerNode: HTMLElement) => {
                                            return triggerNode.parentNode as HTMLElement;
                                        }}
                                    >
                                        <div
                                            className={styles['user-info-not']}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            <IconFont type="icon-denglu"></IconFont>
                                        </div>
                                    </Dropdown>
                                )}
                            </>
                        )}
                        <div
                            className={styles['icon-menu']}
                            onClick={(e) => {
                                e.preventDefault();
                                setIsShow((prev) => {
                                    return !prev;
                                });
                            }}
                        >
                            {isShow ? (
                                <IconFont type="icon-guanbi"></IconFont>
                            ) : (
                                <IconFont type="icon-caidan"></IconFont>
                            )}
                        </div>
                    </div>
                </div>
                {isShow && !isUserInfoShow && (
                    <MenuSlide
                        onClose={() => {
                            setIsShow(false);
                        }}
                    />
                )}
                {isUserInfoShow && (
                    <UserInfoModal
                        useInfo={useInfo}
                        onClose={() => {
                            setIsUserInfoShow(false);
                        }}
                    />
                )}
            </div>
        </>
    );
};
export default MHeader;
