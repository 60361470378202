import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import { useLayoutContext } from '../layout/context/useLayoutContext';

interface IProps {
    m: ReactNode;
    ipad: ReactNode;
    pc: ReactNode;
}
const TransferCom: React.FC<IProps> = (props) => {
    const { m, ipad, pc } = props;
    const { winWidth = 1025 } = useContext(useLayoutContext);

    const render = () => {
        if (!winWidth) {
            return null;
        }
        if (winWidth < 768 && m) {
            return m;
        } else if (winWidth >= 768 && winWidth <= 1024 && ipad) {
            return ipad;
        } else {
            return pc;
        }
    };

    return <>{render()}</>;
};
export default TransferCom;
