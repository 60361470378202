import { Collapse } from 'antd';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';
import { navList } from '../../../constant';
import type { ItemsType } from '../../../type';
import Link from 'next/link';

const { Panel } = Collapse;
interface IProps {
    onClose?: () => void;
}

const MenuSlide: React.FC<IProps> = (props) => {
    const { onClose } = props;
    const [activeKey, setActiveKey] = useState<string>();
    const router = useRouter();
    const cls = (item: ItemsType) => {
        if (item.children && item.children.some((item2: ItemsType) => item2.url === router.asPath)) {
            return styles.act;
        }
        if (router.asPath === item.url) {
            return styles.act;
        }
        return '';
    };

    useEffect(() => {
        navList.forEach((item) => {
            if (item.url === router.asPath) {
                setActiveKey(item.id);
            }
            if (item.children) {
                item.children.forEach((item2) => {
                    if (item2.url === router.asPath) {
                        setActiveKey(item.id);
                    }
                });
            }
        });
    }, [router]);
    const titleRender = (title: string, id: string, record: ItemsType) => {
        let titlCls = '';
        if (id === activeKey) {
            titlCls = styles['act-dro'];
        } else {
            if (id !== activeKey) {
                titlCls = '';
            } else {
                titlCls = cls(record);
            }
        }
        return (
            <div className={classnames(styles['item-box'], titlCls)}>
                <a className={styles['item-title']}>{intl.get(title)}</a>
                <IconFont type="icon-jiantou" className={styles['icon-arrow']}></IconFont>
            </div>
        );
    };
    return (
        <>
            <div className={styles['slide-menu']}>
                <Collapse
                    accordion
                    bordered={false}
                    activeKey={activeKey}
                    onChange={(val: string | string[]) => {
                        setActiveKey(val as string);
                    }}
                >
                    {navList.map((item) => {
                        if (item.children) {
                            return (
                                <Panel header={titleRender(item.text, item.id, item)} key={item.id} showArrow={false}>
                                    {item.children.map((item2) => {
                                        return (
                                            <Link
                                                key={item2.id}
                                                className={classnames(styles['menu-child-item'], cls(item2))}
                                                href={item2.url}
                                                onClick={() => {
                                                    onClose?.();
                                                }}
                                            >
                                                {intl.get(item2.text)}
                                            </Link>
                                        );
                                    })}
                                </Panel>
                            );
                        }
                        return (
                            <Link
                                className={classnames(styles['menu-item'], cls(item))}
                                href={item.url}
                                key={item.id}
                                onClick={() => {
                                    onClose?.();
                                }}
                            >
                                {intl.get(item.text)}
                            </Link>
                        );
                    })}
                </Collapse>
            </div>
            <div
                className={styles.mask}
                onClick={() => {
                    onClose?.();
                }}
            ></div>
        </>
    );
};
export default MenuSlide;
