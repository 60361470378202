import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import IconFont from '@/components/common/IconFont';

import styles from './index.module.less';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';

const MCustomService: React.FC = () => {
    const isEnLang = intl.getInitOptions().currentLocale === 'en-US';
    const [isQRShow, setIsQRShow] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const router = useRouter();

    const handleMousOnclick = () => {
        if (!isQRShow) {
            setIsQRShow(true);
        } else {
            router.push('/contact/');
        }
    };

    const QRDivRender = () => {
        return isEnLang ? (
            <div className={styles.qr_Container}>
                <div className={styles.qr_en_header}>
                    <span className={styles.qr_en_title}>
                        {intl.get('Contact.MQrEnTitle').d('Scan To Add Customer Service')}
                    </span>
                </div>
                <div className={styles.qr_en_body}>
                    <div className={styles.qr_box}>
                        <div className={styles.qr_en_img}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/finovy-cloud-website/img/contact/m/Telegram_QR.jpg"
                                alt=""
                            />
                        </div>
                        <div
                            className={styles.qr_en_desc}
                            dangerouslySetInnerHTML={{
                                __html: intl.get('Contact.MTelegramQR').d(`QR Code<br>for Telegram`),
                            }}
                        ></div>
                    </div>
                    <div className={styles.qr_box}>
                        <div className={styles.qr_en_img}>
                            <img
                                loading="lazy"
                                src="/business/oss-service/finovy-cloud-website/img/contact/m/Whatsapp_QR.jpg"
                                alt=""
                            />
                        </div>
                        <div
                            className={styles.qr_en_desc}
                            dangerouslySetInnerHTML={{
                                __html: intl.get('Contact.MWhatsapp').d(`QR Code<br>for Whatsapp`),
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.qr_Container}>
                <div className={styles.qr_header}>
                    <span className={styles.qr_title}>{intl.get('Contact.MQrTitle').d('扫一扫添加客服微信')}</span>
                </div>
                <div className={styles.qr_body}>
                    <div className={styles.qr_img}>
                        <img
                            loading="lazy"
                            src="/business/oss-service/finovy-cloud-website/img/contact/m/wx_QR.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                if (isQRShow) {
                    setIsQRShow(false);
                }
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isQRShow]);

    return (
        <>
            <div
                ref={ref}
                className={styles.container}
                onClick={() => {
                    handleMousOnclick();
                }}
            >
                <div className={classnames(styles.content)}>
                    <div className={styles['icon-box']}>
                        <IconFont type="kefu" className={styles.icon}></IconFont>
                    </div>
                </div>
            </div>
            {isQRShow && QRDivRender()}
        </>
    );
};
export default MCustomService;
