export const menuList = [
    {
        key: '01',
        name: 'Menu.CloudProducts',
        isOpen: false,
        children: [
            {
                key: 'c-01',
                name: 'Menu.CloudDesktop',
                url: '/products/clouddesk',
            },
            {
                key: 'c-02',
                name: 'Menu.Isms',
                url: '/products/isms',
            },
            {
                key: 'c-03',
                name: 'Menu.Ocr',
                url: '/products/ocr',
            },
            {
                key: 'c-04',
                name: 'Menu.DigitalHuman',
                url: '/products/digitalhuman',
            },
            {
                id: 'c-055',
                name: 'Menu.Vps',
                url: '/products/vps',
            },
        ],
    },
    {
        key: '02',
        name: 'Menu.CloudBusiness',
        isOpen: false,
        children: [
            {
                key: 'c-041',
                name: 'Menu.AliCloud',
                url: '/products/ali',
            },
            {
                key: 'c-05',
                name: 'Menu.HuaweiCloud',
                url: '/products/huawei',
            },
            {
                key: 'c-06',
                name: 'Menu.TencentCloud',
                url: '/products/tencent',
            },
            {
                key: 'c-07',
                name: 'Menu.BaiduCloud',
                url: '/products/baidu',
            },
        ],
    },
    {
        key: '03',
        name: 'Menu.IDCbusiness',
        isOpen: false,
        children: [
            {
                key: 'c-08',
                name: 'Menu.ServerRent',
                url: '/products/serverrental/',
            },
            {
                key: 'c-09',
                name: 'Menu.ServerHosting',
                url: '/products/serverhosting/',
            },
            {
                key: 'c-10',
                name: 'Menu.CabinetRental',
                url: '/products/racksrental/',
            },
            {
                key: 'c-11',
                name: 'Menu.ServerProcurement',
                url: '/products/serverprocure/',
            },
        ],
    },
];
