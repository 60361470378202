export const navList = [
    {
        id: '01',
        text: 'Menu.Home',
        url: '/',
    },
    {
        id: '06',
        text: 'Menu.CloudProducts',
        children: [
            {
                id: 'c-011',
                text: 'Menu.CloudDesktop',
                desc: 'Menu.CloudDesktopDesc',
                url: '/products/clouddesk',
            },
            // {
            //     id: 'c-022',
            //     text: 'Menu.CloudRendering',
            //     desc: 'Menu.CloudRenderingDesc',
            //     url: '/products/cloudrender/',
            // },
            {
                id: 'c-033',
                text: 'Menu.Isms',
                desc: 'Menu.IsmsDesc',
                url: '/products/isms',
            },
            // {
            //     id: 'c-034',
            //     text: 'Menu.Robot',
            //     desc: 'Menu.RobotDesc',
            //     url: '/products/robot/',
            // },
            {
                id: 'c-044',
                text: 'Menu.Ocr',
                desc: 'Menu.OcrDesc',
                url: '/products/ocr',
            },
            {
                id: 'c-054',
                text: 'Menu.DigitalHuman',
                desc: 'Menu.DigitalHumanDesc',
                url: '/products/digitalhuman',
            },
            {
                id: 'c-055',
                text: 'Menu.Vps',
                desc: 'Menu.VpsDesc',
                url: '/products/vps',
            },
        ],
    },
    {
        id: '02',
        text: 'Menu.CloudBusiness',
        children: [
            {
                id: 'c-01',
                text: 'Menu.HuaweiCloud',
                desc: 'Menu.ProvideChoices',
                url: '/products/huawei',
            },
            {
                id: 'c-02',
                text: 'Menu.BaiduCloud',
                desc: 'Menu.NetInfrastructure',
                url: '/products/baidu',
            },
            {
                id: 'c-03',
                text: 'Menu.AliCloud',
                desc: 'Menu.ReduceITExp',
                url: '/products/ali',
            },
            {
                id: 'c-04',
                text: 'Menu.TencentCloud',
                desc: 'Menu.ProvideExclusiveLink',
                url: '/products/tencent',
            },
        ],
    },

    {
        id: '03',
        text: 'Menu.IDCbusiness',
        children: [
            {
                id: 'c-05',
                text: 'Menu.ServerHosting',
                desc: 'Menu.ProvideTechnical',
                url: '/products/serverhosting',
            },
            {
                id: 'c-06',
                text: 'Menu.ServerRent',
                desc: 'Menu.HighPerformance',
                url: '/products/serverrental',
            },
            {
                id: 'c-07',
                text: 'Menu.ServerProcurement',
                desc: 'Menu.OneToOneCustomized',
                url: '/products/serverprocure',
            },
            {
                id: 'c-08',
                text: 'Menu.CabinetRental',
                desc: 'Menu.ProvideStable',
                url: '/products/racksrental',
            },
        ],
    },
    {
        id: '04',
        text: 'Menu.AoutUs',
        url: '/about',
    },
    {
        id: '05',
        text: 'Menu.ContactUs',
        url: '/contact',
    },
];
